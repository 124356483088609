import { glsGetOidFromToken } from '@gls-its-frontends/common/gls-helpers';
import { defineStore } from 'pinia';
import { computed, ComputedRef, ref, type Ref, watch } from 'vue';
import { ROLES_ALLOWED_TO_USE_PPN } from '@/constants/roles-allowed-to-use-ppn';
import { LoginType } from '@/enums/login-type';
import { PartnerParcelNumberType } from '@/enums/partner-parcel-number-type';
import Role from '@/enums/role';
import { type AttributeStoreData } from '@/types';

const mainStoreKey: string = 'main';

const mainStore = defineStore(
    mainStoreKey,
    () => {
        const attributeStoreData: Ref<AttributeStoreData | null> = ref(null);
        const accessToken: Ref<string | null> = ref(null);
        const username: Ref<string | null> = ref(null);
        const userId: Ref<string> = ref('GPP_Frontend');
        const loginType: Ref<LoginType | null> = ref(null);
        const tokenShouldBeRefreshed: Ref<boolean> = ref(false);

        const isAllowedToUsePPN: ComputedRef<boolean> = computed((): boolean => {
            if (!attributeStoreData.value?.role) {
                return false;
            }

            return attributeStoreData.value.role.some((role: Role): boolean => ROLES_ALLOWED_TO_USE_PPN.includes(role));
        });
        const partnerParcelNumberTypes: ComputedRef<PartnerParcelNumberType[]> = computed(
            (): PartnerParcelNumberType[] => attributeStoreData.value?.partnerParcelNumberTypes || []
        );
        const oid: ComputedRef<string | null> = computed((): string | null => glsGetOidFromToken(accessToken.value));
        const isCustomer: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.CUSTOMER_FRONTEND));
        const isAdmin: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.GLS_ADMIN));

        watch(oid, (newOid: string | null): void => {
            if (newOid === attributeStoreData.value?.oid) {
                return;
            }

            attributeStoreData.value = null;
        });

        return {
            attributeStoreData,
            oid,
            accessToken,
            username,
            userId,
            loginType,
            isCustomer,
            isAdmin,
            tokenShouldBeRefreshed,
            isAllowedToUsePPN,
            partnerParcelNumberTypes
        };
    },
    {
        persist: {
            pick: ['attributeStoreData', 'tokenShouldBeRefreshed']
        }
    }
);

export default mainStore;
