import { storeToRefs } from 'pinia';
import mainStore from '@/stores/main-store';

const useMainStore = () => {
    const {
        accessToken,
        oid,
        attributeStoreData,
        userId,
        username,
        loginType,
        isCustomer,
        isAdmin,
        tokenShouldBeRefreshed,
        isAllowedToUsePPN,
        partnerParcelNumberTypes
    } = storeToRefs(mainStore());

    return {
        accessToken,
        oid,
        attributeStoreData,
        username,
        userId,
        loginType,
        isCustomer,
        isAdmin,
        tokenShouldBeRefreshed,
        isAllowedToUsePPN,
        partnerParcelNumberTypes
    };
};

export default useMainStore;
